<template>
  <v-toolbar
    flat
    dense
  >
    <!-- 重新整理 -->
    <v-tooltip top>
      <template v-slot:activator="{on}">
        <v-btn icon v-on="on" @click="listActions.index()">
          <v-icon>fa fa-sync-alt</v-icon>
        </v-btn>
      </template>
      <span>{{'action.refresh'| t}}</span>
    </v-tooltip>

    <!-- 顯示模式 -->
    <displayMode :list-key="listKey"></displayMode>

    <!-- 批次操作 -->
    <batch v-if="hasData" :list-key="listKey"></batch>

    <!-- 全選/取消全選 -->
    <v-btn
      v-if="createSelectAllButton"
      @click="toggleSelectAll"
      small
      outlined
      class="mx-2"
      color="primary"
    >
      <span v-if="!hasSelectedAll">{{'action.select_all'| t}}</span>
      <span v-if="hasSelectedAll">{{'action.cancel_select_all'| t}}</span>
    </v-btn>

    <listExportExcel
      :list-key="listKey"
    ></listExportExcel>

    <!-- 額外列表操作 -->
    <listMetaAction
      :list-key="listKey"
    ></listMetaAction>

    <!-- 快速新增功能 -->
    <listCreateButton
      :list-key="listKey"
    ></listCreateButton>
  </v-toolbar>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    listKey: String,
  },
  methods: {
    toggleSelectAll() {
      // 已全選, 取消全選
      if(this.hasSelectedAll) {
        this.$store.dispatch(`list/${this.listKey}/setSelected`, [])
        return
      }


      // 未全選, 執行全選
      const selected = this.listData.map(row => {
        if(typeof this.listConfig.selectedData === 'function') {
          return this.listConfig.selectedData(row)
        }

        return { [this.targetKey]: row[this.targetKey] }
      })
      this.$store.dispatch(`list/${this.listKey}/setSelected`, selected)
    },
  },
  computed: {
    createSelectAllButton() {
      if(!this.hasData) return false
      if(!this.hasSelectFeature) return false
      if(this.isPopupMode) return false // module selector模式不使用全選toggle功能
      return true
    },
    hasSelectFeature() {
      return this.$store.getters[`list/${this.listKey}/hasSelectFeature`]
    },
    usedListEmpty() {
      return this.$store.getters[`list/${this.listKey}/usedListEmpty`]
    },
    isPopupMode() {
      return this.$store.getters[`list/${this.listKey}/isPopupMode`]
    },
    selectorSingleMode() {
      return this.$store.getters[`list/${this.listKey}/selectorSingleMode`]
    },
    hasData() {
      return this.$store.getters[`list/${this.listKey}/hasData`]
    },
    targetKey() {
      return this.$store.getters[`list/${this.listKey}/targetKey`]
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
    hasSelectedAll() {
      return this.$store.getters[`list/${this.listKey}/hasSelectedAll`]
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`]
    },
    listData() {
      return this.$store.getters[`list/${this.listKey}/data`]
    },
  },
  components: {
    displayMode: () => import('@/components/list/listToolbar/displayMode.vue'),
    batch: () => import('@/components/list/listToolbar/batch.vue'),
    listMetaAction: () => import('@/components/list/listMetaAction.vue'),
    listCreateButton: () => import('@/components/list/listCreateButton.vue'),
    listExportExcel: () => import('@/components/list/listExportExcel.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
